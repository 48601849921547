import React, { Component } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import Icon from './Icon';

class NestableItem extends Component {
    static propTypes = {
        item: PropTypes.object,
        activeItem: PropTypes.object,
        isCopy: PropTypes.bool,
        options: PropTypes.object,
        index: PropTypes.number,
        identifier: PropTypes.string,
        indentSize: PropTypes.number,
        alwaysRenderCollapseIcon: PropTypes.bool
    };

    renderCollapseIcon = ({ isCollapsed }) => (
        <Icon
            className={cn('nestable__item__icon', {
                'icon-plus-gray': isCollapsed,
                'icon-minus-gray': !isCollapsed
            })}
        />
    );

    componentDidUpdate(prevProps) {
        if (this.props.inBricks && prevProps.collapsedGroups?.length !== this.props.collapsedGroups?.length) {
            this.setBricksChildHeight();
        }
    }

    componentDidMount() {
        if (this.props.inBricks) {
            this.setBricksChildHeight();
        }
    }

    /**
     * In bricks we show a vertical line on the left side of each item. In almost all cases the line has the full height minus
     * a certain amount of pixels (to connect with the round line). However, in some cases (e.g. multichannel bricks), the line
     * should stop where the last item starts. We use the function below to determine what the height of the last child in the bricks
     * tree is, so that we can subtract that amount from the total line height.
     */
    setBricksChildHeight = () => {
        const className = `nestable__item-${this.props.item.id}`;
        const element = document.getElementsByClassName(className);

        try {
            const child = element[0].querySelector('ol:last-child');
            const grandChild = child.children[child.children.length - 1];
            const bricksChildHeight = grandChild?.offsetHeight;
            this.setState({ bricksChildHeight });
        } catch (error) {}
    };

    render() {
        const { item, isCopy, options, activeItem, isLastItem, actionRow, level, identifier, collapsedGroups, inBricks, indentSize } = this.props;
        const { dragItem, renderItem, handler, childrenProp, renderCollapseIcon, draggable = this.renderCollapseIcon } = options;

        const isCollapsed = options.isCollapsed(item);
        const isDragging = !isCopy && dragItem && dragItem[identifier] === item[identifier];
        const hasChildren = item[childrenProp] && item[childrenProp].length > 0;

        let rowProps = {};
        let handlerProps = {};
        let Handler;

        if (!isCopy) {
            if (draggable && dragItem) {
                rowProps = {
                    ...rowProps,
                    onMouseEnter: (e) => options.onMouseEnter(e, item)
                };
            } else {
                handlerProps = {
                    ...handlerProps,
                    draggable,
                    ...(draggable && { onDragStart: (e) => options.onDragStart(e, item) })
                };
            }
        }

        if (handler) {
            Handler = (
                <span className="nestable-item-handler" {...handlerProps}>
                    {handler}
                </span>
            );
            //Handler = React.cloneElement(handler, handlerProps);
        } else {
            rowProps = {
                ...rowProps,
                ...handlerProps
            };
        }

        const activeChild = item[childrenProp] && activeItem && !!item[childrenProp].find((item) => item[identifier] === activeItem[identifier]);
        const currentItem = activeItem && item[identifier] === activeItem[identifier];

        const collapseIcon =
            hasChildren || item?.type === 'container' ? (
                <span
                    onClick={(e) => {
                        e.stopPropagation();
                        options.onToggleCollapse(item);
                    }}>
                    {renderCollapseIcon({ isCollapsed, activeChild, currentItem })}
                </span>
            ) : null;

        const baseClassName = 'nestable__item' + (isCopy ? '-copy' : '');
        const itemProps = {
            className: cn(baseClassName, baseClassName + '-' + item[identifier], {
                '--is-dragging': isDragging,
                [baseClassName + '--with-children']: hasChildren,
                [baseClassName + '--children-open']: hasChildren && !isCollapsed,
                [baseClassName + '--children-collapsed']: hasChildren && isCollapsed
            })
        };

        const content = renderItem({ item, collapseIcon, handler: Handler, level: level + 1, isLastItem, toggleItem: options.onToggleCollapse });

        if (!content) return null;

        return (
            <li {...itemProps}>
                {inBricks && (
                    <div
                        className="nestable__item__bricks-vertical-line"
                        style={{ height: `calc(100% - 39px - ${this.state?.bricksChildHeight || 0}px)` }}></div>
                )}

                <div className="nestable__item__name" {...rowProps}>
                    {content}
                </div>

                {!isCollapsed && (
                    <ol
                        style={{ paddingLeft: indentSize ? `${indentSize}px` : '40px' }}
                        className={cn(
                            'nestable__list',
                            hasChildren && 'nestable__list-children',
                            (activeChild || currentItem) && 'nestable__list-children--active-child'
                        )}>
                        {hasChildren &&
                            item[childrenProp].map((child, i) => {
                                return (
                                    <NestableItem
                                        key={i + (child.id || child.key || child[identifier])}
                                        identifier={identifier}
                                        item={child}
                                        options={options}
                                        level={level + 1}
                                        isCopy={isCopy}
                                        activeItem={activeItem}
                                        isLastItem={item[childrenProp].length === i + 1}
                                        actionRow={actionRow}
                                        collapsedGroups={collapsedGroups}
                                        inBricks={inBricks}
                                        indentSize={indentSize}
                                    />
                                );
                            })}
                        {actionRow && actionRow(item)}
                    </ol>
                )}
            </li>
        );
    }
}

export default NestableItem;
