// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./icon-plus-gray.svg");
var ___CSS_LOADER_URL_IMPORT_1___ = require("./icon-minus-gray.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".nestable-icon {\n  position: relative;\n  display: inline-block;\n  width: 20px;\n  height: 20px;\n  background-color: transparent;\n  background-position: center center;\n  background-repeat: no-repeat;\n}\n.nestable-icon:before {\n  content: '-';\n  display: inline-block;\n  width: 0;\n  height: 0;\n  overflow: hidden;\n}\n/* 20x20 */\n.icon-plus-gray {\n  width: 20px;\n  height: 20px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.icon-minus-gray {\n  width: 20px;\n  height: 20px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n", ""]);
// Exports
module.exports = exports;
