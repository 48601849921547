import React, { Component } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import PropTypes from 'prop-types';
import update from 'react-addons-update';
import cn from 'classnames';
import { isEqual } from 'lodash';
import { isArray, closest, getOffsetRect, getTotalScroll, getTransformProps, listWithChildren, getAllNonEmptyNodesIds, createItemPath } from './utils';
import NestableItem from './NestableItem';
import './Nestable.scss';

class Nestable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            items: [],
            itemsOld: null, // snap copy in case of canceling drag
            dragItem: null,
            isDirty: false,
            collapsedGroups: []
        };

        /**
         * If the prop 'collapsed' is set, we need to set the state 'collapsedGroups' with the value of the prop 'collapsed'.
         * If the prop 'collapsed' is a boolean, we need to set the state 'collapsedGroups' with the value of all the items' keys. So when the prop openGroupsOnActiveItemChange is set, we can set the state 'collapsedGroups' with the value of the path of the active item. So the groups will open on active item change.
         * If the prop 'collapsed' is an array, we need to set the state 'collapsedGroups' with the value of the prop 'collapsed'. So we can control the collapsed groups from the parent component.
         */
        if (props.collapsed !== undefined) {
            if (typeof props.collapsed === 'boolean') {
                if (props.collapsed) {
                    const { items } = props;
                    this.state.collapsedGroups = this.getItemKeys(items);
                }
            } else if (Array.isArray(props.collapsed)) {
                const collapsedSet = new Set(props.collapsed);
                this.state.collapsedGroups = [...collapsedSet];
            }
        }

        this.el = null;
        this.elCopyStyles = null;
        this.mouse = {
            last: { x: 0 },
            shift: { x: 0 }
        };
    }

    static propTypes = {
        className: PropTypes.string,
        items: PropTypes.arrayOf(
            PropTypes.shape({
                key: PropTypes.any
            })
        ),
        activeItem: PropTypes.shape({
            key: PropTypes.any
        }),
        threshold: PropTypes.number,
        maxDepth: PropTypes.number,
        collapsed: PropTypes.oneOfType([PropTypes.bool, PropTypes.arrayOf(PropTypes.string)]),
        group: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        childrenProp: PropTypes.string,
        renderItem: PropTypes.func,
        alwaysRenderCollapseIcon: PropTypes.bool,
        renderCollapseIcon: PropTypes.func,
        handler: PropTypes.node,
        onChange: PropTypes.func,
        onChangeCollapsed: PropTypes.func,
        confirmChange: PropTypes.func,
        nestableBlocks: PropTypes.array,
        draggable: PropTypes.bool,
        identifier: PropTypes.string,
        // You can send an object with css styling that shows another element for each nestable item (only visible if styling is sent)
        extraElementStyle: PropTypes.object,
        indentSize: PropTypes.number,
        openGroupsOnActiveItemChange: PropTypes.bool
    };

    static defaultProps = {
        identifier: 'key',
        draggable: true,
        items: [],
        activeItem: undefined,
        nestableBlocks: null,
        threshold: 30,
        maxDepth: 10,
        collapsed: false,
        extraElementStyle: null,
        group: Math.random().toString(36).slice(2),
        childrenProp: 'children',
        alwaysRenderCollapseIcon: false,
        openGroupsOnActiveItemChange: false,
        renderItem: ({ item }) => item.toString(),
        onChange: () => {},
        onChangeCollapsed: () => {},
        confirmChange: () => true
    };

    componentDidMount() {
        let { items } = this.props;
        const { childrenProp } = this.props;

        // make sure every item has property 'children'
        items = listWithChildren(items, childrenProp);
        this.setState({ items });
    }

    componentDidUpdate(prevProps, prevState) {
        const { items: itemsNew, childrenProp, onChangeCollapsed, identifier } = this.props;
        const isPropsUpdated = !isEqual(prevProps, this.props);

        if (!isEqual(prevState.collapsedGroups, this.state.collapsedGroups) && onChangeCollapsed) {
            onChangeCollapsed(this.state.collapsedGroups);
        }

        /**
         * Set new collapsed groups when the active item changes.
         */
        if (this.props.openGroupsOnActiveItemChange) {
            const activeItemChanged = (() => {
                if (!prevProps.activeItem && this.props.activeItem) return true;
                if (prevProps.activeItem && !this.props.activeItem) return false;
                if (prevProps.activeItem && this.props.activeItem && prevProps.activeItem[identifier] !== this.props.activeItem[identifier]) return true;
                return false;
            })();

            if (activeItemChanged) {
                const path = createItemPath(itemsNew, this.props.activeItem[identifier], identifier);
                if (!path) return;

                const pathKeys = path.split('.');
                const newCollapsedGroups = this.state.collapsedGroups.filter((itemKey) => !pathKeys.includes(itemKey));
                this.setState({ collapsedGroups: newCollapsedGroups });
            }
        }

        if (isPropsUpdated) {
            this.stopTrackMouse();

            this.setState({
                items: listWithChildren(itemsNew, childrenProp),
                dragItem: null,
                isDirty: false
            });
        }
    }

    componentWillUnmount() {
        this.stopTrackMouse();
    }

    /**
     * Get the key for every item.
     */
    getItemKeys = (items) => {
        const { identifier } = this.props;

        const itemKeys = [];

        const getItemIdentifiers = (items) => {
            items.forEach((item) => {
                itemKeys.push(item[identifier]);

                if (item.children && item.children.length) {
                    getItemIdentifiers(item.children);
                }
            });
        };

        getItemIdentifiers(items);

        return itemKeys;
    };

    // ––––––––––––––––––––––––––––––––––––
    // Public Methods
    // ––––––––––––––––––––––––––––––––––––
    collapse = (itemIds) => {
        const { childrenProp, collapsed } = this.props;
        const { items } = this.state;

        if (itemIds === 'NONE') {
            this.setState({
                collapsedGroups: collapsed ? getAllNonEmptyNodesIds(items, childrenProp) : []
            });
        } else if (itemIds === 'ALL') {
            this.setState({
                collapsedGroups: collapsed ? [] : getAllNonEmptyNodesIds(items, childrenProp)
            });
        } else if (isArray(itemIds)) {
            this.setState({
                collapsedGroups: getAllNonEmptyNodesIds(items, childrenProp).filter((id) => (itemIds.indexOf(id) > -1) ^ collapsed)
            });
        }
    };

    // ––––––––––––––––––––––––––––––––––––
    // Methods
    // ––––––––––––––––––––––––––––––––––––
    startTrackMouse = () => {
        document.addEventListener('mousemove', this.onMouseMove);
        document.addEventListener('mouseup', this.onDragEnd);
        document.addEventListener('keydown', this.onKeyDown);
    };

    stopTrackMouse = () => {
        document.removeEventListener('mousemove', this.onMouseMove);
        document.removeEventListener('mouseup', this.onDragEnd);
        document.removeEventListener('keydown', this.onKeyDown);
        this.elCopyStyles = null;
    };

    moveItem({ dragItem, pathFrom, pathTo }, extraProps = {}) {
        const { childrenProp, confirmChange } = this.props;
        const dragItemSize = this.getItemDepth(dragItem);
        let { items } = this.state;

        // the remove action might affect the next position,
        // so update next coordinates accordingly
        const realPathTo = this.getRealNextPath(pathFrom, pathTo, dragItemSize);

        // Prevents that a group will be child of another group
        // if (dragItem.type === 'group' && realPathTo.length > 1) return;

        if (realPathTo.length === 0) return;

        // user can validate every movement
        const destinationPath = realPathTo.length > pathTo.length ? pathTo : pathTo.slice(0, -1);
        const destinationParent = this.getItemByPath(destinationPath);

        //prevent blocks to be placed inside blocks
        if (destinationParent && destinationParent.itemType === 'block' && dragItem.itemType === 'block') {
            realPathTo.pop();
            realPathTo.forEach((num, index) => {
                realPathTo[index] = num + 1;
            });
        }

        if (!confirmChange(dragItem, destinationParent)) return;

        const removePath = this.getSplicePath(pathFrom, {
            numToRemove: 1,
            childrenProp: childrenProp
        });

        const insertPath = this.getSplicePath(realPathTo, {
            numToRemove: 0,
            itemsToInsert: [dragItem],
            childrenProp: childrenProp
        });

        items = update(items, removePath);
        items = update(items, insertPath);

        this.setState({
            items,
            isDirty: true,
            ...extraProps
        });
    }

    tryIncreaseDepth(dragItem) {
        const { maxDepth, childrenProp, nestableBlocks, identifier } = this.props;
        const pathFrom = this.getPathById(dragItem[identifier]);
        const itemIndex = pathFrom[pathFrom.length - 1];
        const newDepth = pathFrom.length + this.getItemDepth(dragItem);

        // has previous sibling and isn't at max depth
        if (itemIndex > 0 && newDepth <= maxDepth) {
            const prevSibling = this.getItemByPath(pathFrom.slice(0, -1).concat(itemIndex - 1));

            if (
                prevSibling.type === 'text' ||
                prevSibling.type === 'image' ||
                prevSibling.type === 'video' ||
                prevSibling.type === 'lottie' ||
                prevSibling.type === 'divider' ||
                prevSibling.type === 'alert' ||
                prevSibling.type === 'dropdown' ||
                prevSibling.type === 'feedSelectorInput' ||
                //Don't allow to nesting inside inputs
                (prevSibling.itemType && prevSibling.itemType === 'input') ||
                //only allow nesting for specified blocktypes if provided.
                (nestableBlocks && !nestableBlocks.includes(prevSibling.type))
            ) {
                return;
            }

            const pathTo = pathFrom
                .slice(0, -1)
                .concat(itemIndex - 1)
                .concat(prevSibling[childrenProp].length);

            let collapseProps = {};
            if (this.isCollapsed(prevSibling)) {
                collapseProps = this.onToggleCollapse(prevSibling, true);
            }

            this.moveItem({ dragItem, pathFrom, pathTo }, collapseProps);
        }
    }

    tryDecreaseDepth(dragItem) {
        const { childrenProp, identifier } = this.props;
        const pathFrom = this.getPathById(dragItem[identifier]);
        const itemIndex = pathFrom[pathFrom.length - 1];

        // has parent
        if (pathFrom.length > 1) {
            const parent = this.getItemByPath(pathFrom.slice(0, -1));

            // is last (by order) item in array
            if (itemIndex + 1 === parent[childrenProp].length) {
                const pathTo = pathFrom.slice(0, -1);
                pathTo[pathTo.length - 1] += 1;

                // if collapsed by default
                // and is last (by count) item in array
                // remove this node from list of open nodes
                let collapseProps = {};
                if (parent[childrenProp].length === 1) {
                    collapseProps = this.onToggleCollapse(parent, true);
                }

                this.moveItem({ dragItem, pathFrom, pathTo }, collapseProps);
            }
        }
    }

    dragApply() {
        const { onChange, childrenProp } = this.props;
        const { items, isDirty, dragItem } = this.state;

        // We use the path to get the parent item
        const parentPath = cloneDeep(this.getPathById(dragItem.key, items));
        parentPath.pop();
        let parentItem = null;
        if (parentPath.length > 0) {
            parentItem = items[parentPath.shift() || 0];
            parentPath.forEach((path) => {
                if (parentItem && parentItem[childrenProp]) {
                    parentItem = parentItem[childrenProp][path];
                } else {
                    parentItem = null;
                }
            });
        }

        this.setState({
            itemsOld: null,
            dragItem: null,
            isDirty: false
        });

        onChange && isDirty && onChange(items, dragItem, parentItem);
    }

    dragRevert() {
        const { itemsOld } = this.state;

        this.setState({
            items: itemsOld,
            itemsOld: null,
            dragItem: null,
            isDirty: false
        });
    }

    // ––––––––––––––––––––––––––––––––––––
    // Getter methods
    // ––––––––––––––––––––––––––––––––––––
    getPathById(key, items = this.state.items) {
        const { childrenProp, identifier } = this.props;
        let path = [];

        items.every((item, i) => {
            if (!item) return;

            if (item[identifier] === key) {
                path.push(i);
            } else if (item[childrenProp]) {
                const childrenPath = this.getPathById(key, item[childrenProp]);

                if (childrenPath.length) {
                    path = path.concat(i).concat(childrenPath);
                }
            }

            return path.length === 0;
        });

        return path;
    }

    getItemByPath(path, items = this.state.items) {
        const { childrenProp } = this.props;
        let item = null;

        path.forEach((index) => {
            const list = item ? item[childrenProp] : items;
            item = list[index];
        });

        return item;
    }

    getItemDepth = (item) => {
        const { childrenProp } = this.props;
        let level = 1;

        if (item[childrenProp] && item[childrenProp].length > 0) {
            const childrenDepths = item[childrenProp].map(this.getItemDepth);
            level += Math.max(...childrenDepths);
        }

        return level;
    };

    getSplicePath(path, options = {}) {
        const splicePath = {};
        const numToRemove = options.numToRemove || 0;
        const itemsToInsert = options.itemsToInsert || [];
        const lastIndex = path.length - 1;
        let currentPath = splicePath;

        path.forEach((index, i) => {
            if (i === lastIndex) {
                currentPath.$splice = [[index, numToRemove, ...itemsToInsert]];
            } else {
                const nextPath = {};
                currentPath[index] = { [options.childrenProp]: nextPath };
                currentPath = nextPath;
            }
        });

        return splicePath;
    }

    getRealNextPath(prevPath, nextPath, dragItemSize) {
        const { childrenProp, maxDepth } = this.props;
        const ppLastIndex = prevPath.length - 1;
        const npLastIndex = nextPath.length - 1;
        const newDepth = nextPath.length + dragItemSize - 1;

        if (prevPath.length < nextPath.length) {
            // move into depth
            let wasShifted = false;

            // if new depth exceeds max, try to put after item instead of into item
            if (newDepth > maxDepth && nextPath.length) {
                return this.getRealNextPath(prevPath, nextPath.slice(0, -1), dragItemSize);
            }

            return nextPath.map((nextIndex, i) => {
                if (wasShifted) {
                    return i === npLastIndex ? nextIndex + 1 : nextIndex;
                }

                if (typeof prevPath[i] !== 'number') {
                    return nextIndex;
                }

                if (nextPath[i] > prevPath[i] && i === ppLastIndex) {
                    wasShifted = true;
                    return nextIndex - 1;
                }

                return nextIndex;
            });
        } else if (prevPath.length === nextPath.length) {
            // if move bottom + move to item with children --> make it a first child instead of swap
            if (nextPath[npLastIndex] > prevPath[npLastIndex]) {
                const target = this.getItemByPath(nextPath);

                if (newDepth < maxDepth && target[childrenProp] && target[childrenProp].length && !this.isCollapsed(target)) {
                    return nextPath
                        .slice(0, -1)
                        .concat(nextPath[npLastIndex] - 1)
                        .concat(0);
                }
            }
        }

        return nextPath;
    }

    getItemOptions() {
        const { renderItem, renderCollapseIcon, alwaysRenderCollapseIcon, handler, childrenProp, draggable } = this.props;
        const { dragItem } = this.state;

        return {
            dragItem,
            childrenProp,
            renderItem,
            alwaysRenderCollapseIcon,
            renderCollapseIcon,
            handler,
            draggable,

            onDragStart: this.onDragStart,
            onMouseEnter: this.onMouseEnter,
            isCollapsed: this.isCollapsed,
            onToggleCollapse: this.onToggleCollapse
        };
    }

    isCollapsed = (item) => {
        const { identifier } = this.props;
        const { collapsedGroups } = this.state;
        return !!(collapsedGroups.indexOf(item[identifier]) > -1);
    };

    // ––––––––––––––––––––––––––––––––––––
    // Click handlers or event handlers
    // ––––––––––––––––––––––––––––––––––––
    onDragStart = (e, item) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        if (this.props.onDragStart) {
            this.props.onDragStart(item);
        }

        this.el = closest(e.target, '.nestable__item');

        this.startTrackMouse();
        this.onMouseMove(e);

        this.setState({
            dragItem: item,
            itemsOld: this.state.items
        });
    };

    onDragEnd = (e, isCancel) => {
        e && e.preventDefault();

        if (this.props.onDragEnd) {
            this.props.onDragEnd(this.state.dragItem);
        }

        this.stopTrackMouse();
        this.el = null;

        isCancel ? this.dragRevert() : this.dragApply();
    };

    onMouseMove = (e) => {
        const { group, threshold, scrollableDiv = false } = this.props;
        const { dragItem } = this.state;
        const { clientX, clientY } = e;
        const transformProps = getTransformProps(clientX, clientY);
        const elCopy = document.querySelector('.nestable-' + group + ' .nestable__drag__layer > .nestable__list');

        if (!this.elCopyStyles) {
            const offset = getOffsetRect(this.el);
            const scroll = getTotalScroll(this.el);

            this.elCopyStyles = {
                marginTop: offset.top - clientY - (scrollableDiv ? 0 : scroll.top),
                marginLeft: offset.left - clientX - scroll.left,
                ...transformProps
            };
        } else {
            this.elCopyStyles = {
                ...this.elCopyStyles,
                ...transformProps
            };
            for (const key in transformProps) {
                if (transformProps.hasOwnProperty(key)) {
                    elCopy.style[key] = transformProps[key];
                }
            }

            const diffX = clientX - this.mouse.last.x;
            if ((diffX >= 0 && this.mouse.shift.x >= 0) || (diffX <= 0 && this.mouse.shift.x <= 0)) {
                this.mouse.shift.x += diffX;
            } else {
                this.mouse.shift.x = 0;
            }
            this.mouse.last.x = clientX;

            if (Math.abs(this.mouse.shift.x) > threshold) {
                if (this.mouse.shift.x > 0) {
                    this.tryIncreaseDepth(dragItem);
                } else {
                    this.tryDecreaseDepth(dragItem);
                }

                this.mouse.shift.x = 0;
            }
        }
    };

    onMouseEnter = (e, item) => {
        if (e) {
            e.preventDefault();
            e.stopPropagation();
        }

        const { childrenProp, identifier } = this.props;
        const { dragItem } = this.state;
        if (dragItem[identifier] === item[identifier]) return;

        const pathFrom = this.getPathById(dragItem[identifier]);
        const pathTo = this.getPathById(item[identifier]);

        // if collapsed by default
        // and move last (by count) child
        // remove parent node from list of open nodes
        let collapseProps = {};
        const isCollapsed = this.isCollapsed(item);
        if (isCollapsed && pathFrom.length > 1) {
            const parent = this.getItemByPath(pathFrom.slice(0, -1));
            if (parent && parent[childrenProp].length === 1) {
                collapseProps = this.onToggleCollapse(parent, true);
            }
        }

        this.moveItem({ dragItem, pathFrom, pathTo }, collapseProps);
    };

    onToggleCollapse = (item, isGetter) => {
        const { identifier } = this.props;
        const { collapsedGroups } = this.state;
        const isCollapsed = this.isCollapsed(item);

        const newState = {
            collapsedGroups: isCollapsed ? collapsedGroups.filter((key) => key !== item[identifier]) : collapsedGroups.concat(item[identifier])
        };

        if (isGetter) {
            return newState;
        } else {
            this.setState(newState);
        }
    };

    onKeyDown = (e) => {
        if (e.which === 27) {
            // ESC
            this.onDragEnd(null, true);
        }
    };

    // ––––––––––––––––––––––––––––––––––––
    // Render methods
    // ––––––––––––––––––––––––––––––––––––
    renderDragLayer() {
        const { group, identifier } = this.props;
        const { dragItem } = this.state;
        const el = document.querySelector('.nestable-' + group + ' .nestable__item-' + dragItem[identifier]);

        let listStyles = {};
        if (el) {
            listStyles.width = el.clientWidth;
        }
        if (this.elCopyStyles) {
            listStyles = {
                ...listStyles,
                ...this.elCopyStyles
            };
        }

        const options = this.getItemOptions();

        return (
            <div className="nestable__drag__layer">
                <ol className="nestable__list" style={listStyles}>
                    <NestableItem item={dragItem} options={options} isCopy />
                </ol>
            </div>
        );
    }

    render() {
        const { group, className, activeItem, actionRow, identifier, inBricks, indentSize } = this.props;
        const { items, dragItem, collapsedGroups = [] } = this.state;
        const options = this.getItemOptions();
        const activeChild = activeItem && !!items.find((item) => item[identifier] === activeItem[identifier]);

        return (
            <div className={cn(className, 'nestable', 'nestable-' + group, { 'is-drag-active': dragItem })}>
                <ol className={cn('nestable__list', activeChild && 'nestable__list--active-child')}>
                    {items.map((item, i) => {
                        return (
                            <NestableItem
                                key={i + (item.id || item.key || item[identifier])}
                                identifier={identifier}
                                level={0}
                                item={item}
                                options={options}
                                activeItem={activeItem}
                                isLastItem={items.length === i + 1}
                                actionRow={actionRow}
                                collapsedGroups={collapsedGroups}
                                inBricks={inBricks}
                                indentSize={indentSize}
                            />
                        );
                    })}
                </ol>

                {dragItem && this.renderDragLayer()}
            </div>
        );
    }
}

export default Nestable;
