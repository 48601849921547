import { push } from 'redux-first-history';
import store from '../../../store';

export default class RouteHelper {
    /**
     * Initialize the firebase app
     */
    static openLink(link) {
        store.dispatch(push(link));
    }
}
